import React from "react";
import "./App.scss";
import Titles from "./components/Titles";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import {
  faReact,
  faVuejs,
  faJsSquare,
  faHtml5,
  faCss3,
  faSass,
  faBootstrap,
  faPython,
  faInstagram,
  faTwitter,
  faFacebook,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { MemoryRouter } from "react-router-dom";

import SeattleMetroRE from "./assets/SeattleMetroRE.jpg";
import MaherDDS from "./assets/MaherDDS.jpg";
import Bellerose from "./assets/Bellerose.jpg";

function App() {
  const domRef = React.useRef();

  const [isVisible, setVisible] = React.useState(true);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    let cleanup = domRef.current;
    observer.observe(cleanup);
    return () => observer.unobserve(cleanup);
  }, []);
  return (
    <div className="App">
      <body>
        <div className="background">
          <section className="title-top">
            <div className="branch-icon">
              <FontAwesomeIcon
                icon={faCodeBranch}
                className="fa-4x"
                style={{ color: "rgb(38, 50, 43)" }}
              />
            </div>
            <div className="name">Kim Duclos</div>
            <div className="subtitle">
              <Titles />
            </div>
            <div className="down-caret fa-4x">
              <MemoryRouter>
                <HashLink smooth to="/#projects">
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    beat
                    style={{ color: "rgb(86, 104, 86)" }}
                  />
                </HashLink>
              </MemoryRouter>
            </div>
          </section>
          <section className="project-section" id="projects">
            <div
              className={`project-title fade-in-section ${
                isVisible ? "is-visible" : ""
              }`}
              ref={domRef}
            >
              Recent Projects
            </div>
            <MemoryRouter>
              <div className="project-list">
                <div
                  className={`project-box fade-in-section ${
                    isVisible ? "is-visible" : ""
                  }`}
                  ref={domRef}
                >
                  {/* <a
                    href="https://SeattleMetroRE.com"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                    <div
                      className="project"
                      style={{
                        backgroundImage: `url(${SeattleMetroRE})`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  {/* </a> */}
                  {/* <FontAwesomeIcon icon={faGithub} className="fa-2x" /> */}
                </div>
                <div
                  className={`project-box fade-in-section ${
                    isVisible ? "is-visible" : ""
                  }`}
                  ref={domRef}
                >
                  {/* <a
                    href="https://BelleroseInteriorDesign.com"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                    <div
                      className="project"
                      style={{
                        backgroundImage: `url(${Bellerose})`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  {/* </a> */}
                  {/* <FontAwesomeIcon icon={faGithub} className="fa-2x" /> */}
                </div>
                <div
                  className={`project-box fade-in-section ${
                    isVisible ? "is-visible" : ""
                  }`}
                  ref={domRef}
                >
                  {/* <a
                    href="https://MaherDDS.com"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                    <div
                      className="project"
                      style={{
                        backgroundImage: `url(${MaherDDS})`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  {/* </a> */}
                  {/* <FontAwesomeIcon icon={faGithub} className="fa-2x" /> */}
                </div>
              </div>
            </MemoryRouter>
          </section>
          <section className="about-section">
            <div className="about-content">
              {/* <div className="about-image">PHOTO</div> */}
              <div className="about-blurb">
                <div className="about-title">About</div>
                <p className="par-one">
                  As a front-end developer, I possess a diverse set of skills
                  and expertise that allow me to excel in creating exceptional
                  digital experiences. With a deep understanding of user
                  interface design principles, coding languages, and project
                  management, I am capable of collaborating with
                  cross-functional teams to deliver innovative and visually
                  appealing web applications.
                </p>
                <br></br>
                <p>
                  My proficiency in front-end development technologies such as
                  React, Vue, HTML, CSS, and JavaScript empowers me to build
                  responsive and interactive user interfaces. I have a keen eye
                  for design and user experience, ensuring that the applications
                  I create are not only visually appealing but also intuitive
                  and user-friendly.
                </p>
              </div>
            </div>
            <div className="skills-section">
              <div className="skills-list">
                <div className="skill">
                  <FontAwesomeIcon icon={faReact} className="fa-4x" />
                  <p className="skill-name">React</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faVuejs} className="fa-4x" />
                  <p className="skill-name">Vue</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faJsSquare} className="fa-4x" />
                  <p className="skill-name">JavaScript</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faHtml5} className="fa-4x" />
                  <p className="skill-name">HTML</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faCss3} className="fa-4x" />
                  <p className="skill-name">CSS</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faSass} className="fa-4x" />
                  <p className="skill-name">Sass</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faBootstrap} className="fa-4x" />
                  <p className="skill-name">Bootstrap</p>
                </div>
                <div className="skill">
                  <FontAwesomeIcon icon={faPython} className="fa-4x" />
                  <p className="skill-name">Python</p>
                </div>
              </div>
            </div>
          </section>
          <section className="footer-section">
            <div className="footer-text">Kim Duclos 2023</div>
            <div className="social-media-footer">
              <div className="social-icon">
                <a
                  href="https://github.com/kimduclos"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faGithub} className="fa-2x" />
                </a>
              </div>
              <div className="social-icon">
                <a
                  href="https://instagram.com/kimduclos_"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="fa-2x" />
                </a>
              </div>
              <div className="social-icon">
                <a
                  href="https://twitter.com/kimduclos_"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="fa-2x" />
                </a>
              </div>
              <div className="social-icon">
                <a
                  href="https://facebook.com/kimduclos.me"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} className="fa-2x" />
                </a>
              </div>
            </div>
          </section>
        </div>
      </body>
    </div>
  );
}

export default App;
