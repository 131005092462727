import { TypeAnimation } from "react-type-animation";

const Titles = () => {
  return (
    <TypeAnimation
      sequence={[
        "Developer",
        1000,
        "Team Lead",
        1000,
        "Designer",
        1000,
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: "2em", display: "inline-block" }}
      repeat={Infinity}
    />
  );
};

export default Titles;
